import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import './owlcarousel/dist/assets/owl.carousel.css';
import './owlcarousel/dist/assets/owl.theme.default.css';
const options = {
    margin: 30,
    responsiveClass: true,
    
    dots: true,
    autoplay: false,
    
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

export class carousel extends Component {
  render() {
    return (
     <>
        <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color">carousel</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <OwlCarousel className="slider-items " {...options}>
    {/* <OwlCarousel className='owl-theme' > */}
        <div className='item' >
            <h4>1</h4>
        </div>
        <div className='item'>
            <h4>2</h4>
        </div>
        <div className='item'>
            <h4>3</h4>
        </div>
        <div className='item'>
            <h4>4</h4>
        </div>
        <div className='item'>
            <h4>5</h4>
        </div>
        <div className='item'>
            <h4>6</h4>
        </div>
        <div className='item'>
            <h4>7</h4>
        </div>
        <div className='item'>
            <h4>8</h4>
        </div>
        <div className='item'>
            <h4>9</h4>
        </div>
        <div className='item'>
            <h4>10</h4>
        </div>
        <div className='item'>
            <h4>11</h4>
        </div>
        <div className='item'>
            <h4>12</h4>
        </div>
</OwlCarousel>
     </>
    )
  }
}

export default carousel