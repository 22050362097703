import "./Loader.css";
import React from "react";
export default function Loader() {
  return (
    <>
      <div className="d-flex justify-content-center mt-5 mx-4">
        <div className=" mx-4 spinner-border text-primary" role="status"></div>
        <span className=" h4 classic-4">Loading...</span>
        {/* <span className="h4  d-block visually-hidden">Loading</span> */}
      </div>

      {/* <div className="main-item mx-auto justify-content-center">
      

        <div className="animated-background col-xs-12">
          <div className="background-masker btn-divide-left"></div>
        </div> */}
{/* 
        <div className="shared-dom">
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
          <div className="sub-rect pure-background"></div>
        </div> */}

        {/* <div className="css-dom"></div> */}
      {/* </div> */}
    </>
  );
}
