import React, { Component } from 'react'
import "./Leadership.css"
export class Leadership extends Component {
  render() {
    return (
     <>
      <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color">LEADERSHIP</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="team" className="section-1 team" >
        <div className="container">
            <div className="row">
                <div className="col-12 align-self-top text-center text-md-left text">
                    <div className="row text-center intro">
                        <div className="col-12">
                            <h2><span className="featured"><span>Leadership</span></span> Team</h2>
                            <span className="pre-title m-auto title-tag ">Pushpak is managed by a group of accomplished professionals under the leadership of the Managing Director.
                            </span>
                        </div>
                    </div>
                    <div>
                        <h4>Our Founder:</h4>
                        <p className="pb-5 founder-details" align="justify">Foundation of our group has been laid down by our Chairman Shri Bharatbhai Modi by establishing Modi Minerals Group. In the late ’70s with main focus on mining sector i.e., by having products such as Bauxite, Limestone &amp; Gypsum in our serving tray, by operating in the state of Gujarat, sufficiently served some of the reputed groups of India; this list also includes Hindalco, Vedanta, Dharamsi Morarji Chemical Company Limited and Larsen and Toubro.</p>

                    </div>
                    <div className="row items text-left">
                        <div className="col-12 col-md-6 p-0">
                            <div className="row item">
                                <div className="col-4 p-0 pr-3 align-self-center ">
                                    <img src="assets/images/team.png" alt="Person" className="person"/>
                                </div>
                                <div className="col-8 align-self-center text-left">
                                    <h4>Mr. Bharatbhai Modi </h4>
                                    <p>Chairman</p>


                                </div>
                            </div>
                            <div className="row item">
                                <div className="col-4 p-0 pr-3  align-self-center img-container">
                                <img src="assets/images/team.png" alt="Person" className="person"/>
                                    {/* <img src="assets/images/rahul-modi01.jpg" alt="Person" className="person"/> */}
                                </div>
                                <div className="col-7 align-self-center text-left">
                                    <h4>Mr. Rahul Modi </h4>
                                    <p>Managing Director</p>

                                    <ul className="navbar-nav social share-list ml-auto">
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://www.facebook.com/rahul.b.modi" className="nav-link"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://twitter.com/rahulmodi76?t=TDNf8Ore5v60wkpDpeIrug&s=09" className="nav-link"><i className="fab fa-twitter"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://www.linkedin.com/in/rahul-modi-111b6760" className="nav-link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://instagram.com/rbmodi76?igshid=YmMyMTA2M2Y=" className="nav-link"><i className="fab fa-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <div className="row item">
                                <div className="col-4 p-0 pr-3 align-self-center img-container ">
                                <img src="assets/images/team.png" alt="Person" className="person"/>
                                    {/* <img src="assets/images/hiren-modi01.jpeg" alt="Person" className="person "/> */}
                                </div>
                                <div className="col-7 align-self-center text-left">
                                    <h4>Mr. Hiren Modi</h4>
                                    <p>Director</p>

                                    <ul className="navbar-nav social share-list ml-auto">
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://www.facebook.com/hiren.modi.790" className="nav-link"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://twitter.com/hirenbmodi?t=aZfYOElNAQRvPLCtzF7K-g&s=09" className="nav-link"><i className="fab fa-twitter"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://www.linkedin.com/in/hiren-modi-63665759" className="nav-link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://instagram.com/hirenmodi83?igshid=YmMyMTA2M2Y=" className="nav-link"><i className="fab fa-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row item">
                                <div className="col-4 p-0 pr-3 align-self-center">
                                    <img src="assets/images/team.png" alt="Person" className="person"/>
                                </div>
                                <div className="col-8 align-self-center text-left">
                                    <h4>Mr. Tushar Modi </h4>
                                    <p>Director</p>

                                    <ul className="navbar-nav social share-list ml-auto">
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://www.facebook.com/tushar.modi.16" className="nav-link"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://www.linkedin.com/in/tushar-modi-31919518" className="nav-link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a Target="_blank" href="https://instagram.com/tushar.modi.16?igshid=YmMyMTA2M2Y=" className="nav-link"><i className="fab fa-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
 
     </>
    )
    
  }
}

export default Leadership