import React, { Component } from 'react'
import "./Media.css"
import OwlCarousel from 'react-owl-carousel';
import './owlcarousel/dist/assets/owl.carousel.css';
import './owlcarousel/dist/assets/owl.theme.default.css';
import video from './pushpak.mp4'
const options = {
    margin: 30,
    responsiveClass: true,
    loop:false,
    dots: true,
    autoplay: true,
    autoplayTimeout:3000,
    smartSpeed: 900,
 
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

export class Media extends Component {
  render() {
    return (
      <>
           <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color">MEDIA</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section className="section-1 odd showcase blog-grid projects bg-light">
        <div className="container ">
            <div className=" text-center intro mt-2">

                
                <h2> <span className="featured" style={{color:"#193863"}}><span>Pushpak Logistics</span></span></h2>


                <div className=" mb-3">
                <video width="100%" height="auto" autoPlay controls loop>
                <source src={video} type="video/mp4"/>
                </video>
                </div>
            </div>
            <div className=" text-center intro mt-2">

                
                <h2> <span className="featured" style={{color:"#193863"}}><span>Awards Ceremony</span></span></h2>


                <div className=" mb-3">

                <OwlCarousel className="slider-items owl-theme owl-carousel " {...options}>
                    
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/pushpak-about-2.jpg" id="myImg" alt="image1"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/pushpak-about-3.jpg" id="myImg" alt="image2"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media01.jpg" id="myImg" alt="image3"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media02.jpg" id="myImg" alt="image4"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media03.jpg" id="myImg" alt="image5"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media04.jpg" id="myImg" alt="image6"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media05.jpg" id="myImg" alt="image7"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media06.jpg" id="myImg" alt="image8"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media07.jpg" id="myImg" alt="image9"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media08.jpg" id="myImg" alt="image10"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media09.jpg" id="myImg" alt="image11"/>
                            </div>
                        </div>
                   
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media11.jpg" id="myImg" alt="image13"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media12.jpg" id="myImg" alt="image14"/>
                            </div>
                        </div>
                     
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media14.jpg" id="myImg" alt="image16"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/media/media15.jpg" id="myImg" alt="image17"/>
                            </div>
                        </div>
                      </OwlCarousel>
                    {/* </div> */}
                </div>
            </div>

        </div>
    </section>
    <section className="section-1 odd showcase blog-grid projects" style={{background: 'eef4ed'}}>
        <div className="container ">
            <div className=" text-center intro ">

              
                <h2> <span className="featured" style={{color:"#193863"}}><span>Gallery</span></span></h2>


                <div className=" mb-2">

                    <OwlCarousel className="owl-carousel owl-theme" {...options}>
        

                     <div className=" mr-3  item ">
                            <div className="img-container img-container1 ">
                                <img src="assets/images/pushpak-trucks-1.jpg" id="myImg" className="img-1" alt="image1"/>
                            </div>
                        </div>
            
             
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/pushpak-trucks-2.jpg" id="myImg" className="img-1" alt="image2"/>
                            </div>
                        </div>
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/truck.png" id="myImg" className="img-1" alt="image3"/>
                            </div>
                        </div>
                       
                        <div className="item mr-3 ">
                            <div className="img-container ">
                                <img src="assets/images/ship.jpeg" id="myImg"  className="img-1" alt="image4"/>
                            </div>
                         </div>
                        
                        <div className="item mr-3 "> 
                             <div className="img-container "> 
                                <img src="assets/images/004.jpg" id="myImg" className="img-1" alt="image5"/> 
                            </div>
                        </div>
                    
                      
                    </OwlCarousel>
                </div>
            </div>

        </div>
    </section>

      </>
    )
  }
}

export default Media