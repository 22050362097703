import React, { Component } from 'react'

export class About extends Component {
   
  render() {
     
    return (
    
      <>
      
      <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
                <div className="swiper-wrapper">
                            <div className="swiper-slide slide-center">

                     
                        <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img"  alt="Banner" />

                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">

                              
                                {/* <!-- <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="index.html" style="color: black">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Career</li>
                                    </ol>
                                </nav> --> */}
                                <h1 className="mb-0 title effect-static-text banner-color" >ABOUT</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
          <section id="single" className="section-1 single bg-white"  > 
            <div className="container">
                <div className="row">
                    
                 
                    <div className="col-12 col-lg-12 p-0 text">
                        <div className="row intro m-0">
                            <div className="col-12">
                                <span className="pre-title m-0">Know about us</span>
                                <div className="title-icon">
                                    
                                    <h2 style={{color:"#164588"}}>Pushpak Logistics</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center" style={{color:"#21333e"}}>
                                <p align="justify"><span ><b>Pushpak Logistics Pvt Ltd:</b></span> 
                                <ul className="ml-3">
                                <li>Pushpak is India’s largest multi-modal transport company supported by highly experienced promoters and run by a team of dedicated staff, who constantly look to achieve excellence and develop new routes in coastal trade.</li>
                                <li>Pushpak is the first forwarder to	container	between Gujarat and Cochin, Mangalore, Chennai, Hazira, Goa and Tuticorin.</li>
                                <li>Pushpak is currently handling over 2,000+ containers a month translating into a majority of market share in India's coastal trade, which makes it the market leader in India's coastal forwarding business.</li>
                                <li>Pushpak has won the support of all major Indian coastal lines such as Avana Logistek Ltd (formerly known as Shreyas Relay Systems Ltd), Shipping Corporation of India Ltd (a Navaratna public sector undertaking), TCI Seaways and Sima Marine India Pvt Ltd.</li>
                                <li>Pushpak has a major	with all the Indian	lines whose vessels are calling at major and minor ports of the country.</li>
                                </ul>
                                </p>
                                <img src="assets/images/about1.png" alt="milestones" width="100%"/>
                                
                                
                    <p align="justify">
                    <span><b>Pushpak Logistics Pvt Ltd:</b></span> 
                    The tremendous growth in Indian GDP and trade has created a
                    new demand for additional transportation facilities. Coastal 
                    transportation in an ideal mode along with road and rail transporation
                    to cope up with the increasing demand. So in 2003, Modi Minerals Group 
                    has stared sea transportation firm to serve their mineral base client,
                    in the name of "Pushpak Trans Suvidha", and started transportation of
                        mineral based cargo through small country craft from Veraval-Porbandar
                        ports to south India. Slowly the group had started a multi modal logistics
                        services or you can say "Green Revolution" in logistics sector, from Popavav
                        port with the keen support of Shreyas Relay Systems (Relay Shipping), Mumbai,
                            And started with 5 -10 containers/vessel. Whic gradually by the vision of management
                            grow up to 100 containers/month and the same time changed the name of our firm to "Pushpak 
                            Logistics Pvt Ltd" and journey begin starts from 2004 mid...</p>

                            <p align="justify">
                            <span ><b>Pushpak Today:</b></span> 
                            Today Pushpak is India's largest coastal freight forwarding
                            company supported by highly experienced promoters and run by 
                            a team of dedicated persons, who constantly look to achieve excellence 
                            and to give a some new routes to costal trade, every time. 
                            Just like to share that Pupshpak is the first forwarder who
                            have started coastal container movements for Manglore & Tuticorin 
                            from Gujarat with the supports of Shreyas Relay Systems Ltd. 
                            Now a days Pushpak is doing 1600+ containers/month with the approximate
                            shares of 30-40% of the total coastal trade and that is the reason only
                                we can proudly say that Pushpak is the market leader in India's costal
                                forwarding business. Pushpak is authorized and recognized as best 
                                forwarder by Shreyas Relay Systems Ltd, who is the pioneer line in coastal trade,
                                as well as Pushpak is the forwarder who has won the support of 
                                all major Indian coastal lines named Shipping Corporation of India Ltd.,
                                Jindal Waterways Ltd (JITFL) and Carawel Logistics. Pushpak is the forwarder
                                , who has major stack with the all Indian coastal lines who's vessels
                                    are calling between Indian coastal ports. Las but not least
                                    , <span><b>
                                    PUSHPAK IS AWARDED AS A 
                                    "BEST FREIGHT FORWARDER OF THE YEAR-DOMESTIC COASTAL MOVEMENT" 
                                    IN YEAR 2012 BY GUJARAT STAR AWARDS AND "GUJARAT JUNCTION AWARDS 
                                    (EXIM AWARDS)" IN YEAR 2012 BY EXIM.
                                    </b></span> </p>
                                
                            </div>
                        </div>        
                    </div>
                    
                 
                    {/* <aside className="col-12 col-lg-4 pl-lg-5 p-0 float-right sidebar">                    
                        <div className="row">
                            <div className="col-12 align-self-center text-left">
                                
                              
                                <div className="gallery row justify-content-center"> */}
                                {/* <a className="col-12 pl-0 item" href="assets/images/pushpak-about-1.jpg">
                                        <img src="assets/images/pushpak-about-1.jpg" alt="Best Freight Forwarder
in year 2012 by gujarat star awards" className="w-100"/>
                                    </a> */}
                                {/* </div>
                            </div>
                        </div>
                    </aside> */}
                </div>
            </div>
        </section>
      </>
    )
  }
}

export default About