import React, { Component } from 'react'
import "./Clients.css"
import OwlCarousel from 'react-owl-carousel';
import './owlcarousel/dist/assets/owl.carousel.css';
import './owlcarousel/dist/assets/owl.theme.default.css';
const option = {
    margin: 30,
    responsiveClass: true,
    loop:true,
    dots: true,
    autoplay: true,
    autoplayTimeout:2000,
    smartSpeed: 500,
    autoplayHoverPause:true,

    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

export class Clients extends Component {
  render() {
    return (
      <>
           <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color">Overview Services</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="partners" className="section-1 offers testimonials bg-white">
        <div className="container">
            <div className="row text-center intro">
                <div className="col-12">
                    <span className="pre-title">Union that achieves results</span>
                    <h2> <span className="featured"><span>Clientele</span></span></h2>
                    <p className="text-max-800">We work with the most reputable companies.</p>
                </div>
            </div>
            <section className="section-1 bg-light mb-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                            <h1>Business Partners</h1>
                        </div>
                    </div>
                    <OwlCarousel className='slider-items owl-theme owl-carousel' {...option}>
                                    <div className="item img-client pt-4">
                                        <img src="./assets/images/client-img/SIMPOLO LOG.jpg"  alt="client-01"/>
                                    </div>
                                    <div className="item img-client pt-4" >
                                        <img src="./assets/images/client-img/SOMANY LOG1.jpg"  alt="client-02"/>
                                    </div>
                                    <div className="item img-client pt-4">
                                        <img src="./assets/images/client-img/KAJARIA LOG.jpg"  alt="client-03"/>
                                    </div>
                                    <div className="item img-client p-4">
                                        <img src="./assets/images/client-img/DALMIA LOG.jpg"  alt="client-04"/>
                                    </div>
                                    <div className="item img-client p-4">
                                        <img src="./assets/images/client-img/ZEALTOP.png" style={{height:'140px'}} alt="client-05"/>
                                    </div>
                                
                    </OwlCarousel>
                    {/* <div className="row pt-3">
                        <div className="col-sm-6 col-md-12 col-lg-12">
                            <div className="card card2 p-4 border">
                                <div className="row mt-2 mb-2 text-center"> */}
                                   
                            {/* </div> */}
                        {/* </div>
                    </div> */}
                </div>
            </section>

                <div className="row  items">
                        <div className="col-12  col-md-6 item">
                            <div className="card card2 ">
                           
                                <i className="icon icon-hover d-flex mx-auto  fas fa-handshake"></i>
                                <h4 className=" hover-2  text-center">Associated Firms</h4>
                                <ul  className="list-about">

                                    <li className="text-justify "><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p> Tushar Mineral Ind</p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>Pushpak International Freight Forwarder </p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>Bajaj Road links </p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>J. B. Modi </p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>JBM Transmarine LLP</p>
                                    </li>
                                </ul>
                         
                                {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                            </div>
                        </div>
                        <div  className="col-12 col-md-6 item">
                            <div className="card card2">
                            <i className="icon d-flex mx-auto  fas fa-handshake"></i>
                                <h4 className=" hover-2 text-center">Supporting Lines</h4>
                                <ul  className="list-about">

                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>Shreays Relay Systems Ltd, Mumbai </p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>Shipping Corporation Of India Ltd (SCI)</p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>TCI SEAWAYS LTD </p>
                                    </li>
                                    <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                        <p>Simatech Shipping </p>
                                    </li>
                                
                                </ul>
                                <p>&nbsp;</p>
                                {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                            </div>
                        </div>
                </div>
          
        </div>
          
    </section>

      </>
    )
  }
}

export default Clients