// import logo from './logo.svg';
import './App.css';
import Navbar from './components/includes/Navbar';
import Footer from './components/includes/Footer';
import React, { Component } from 'react'
import Home from './components/pages/Home';
import Services  from './components/pages/Services';
import About from './components/pages/About';
import Career from './components/pages/Career';
import Contact from './components/pages/Contact';
import Whychooseus from './components/pages/Whychooseus';
import Cleints from './components/pages/Clients';
import Leadership from './components/pages/Leadership';
import Media from './components/pages/Media';
import Ordertracking from './components/pages/Ordertracking';
import Carousel from './components/pages/carousel';
import Vesselschedule from './components/pages/Vesselschedule';
import Documents from './components/pages/Document';

import Demo from './components/pages/demo'
import Vessel1 from './components/pages/vessel1';
import ScrollToTop from './components/pages/ScrollToTop';
import {
  BrowserRouter as Router,
  Route,Routes
} from "react-router-dom";
export class App extends Component {
  render() {
    return (<>
          <Router>
          <ScrollToTop/>
              <Navbar/>
                  <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/Services" element={<Services />} />
                    <Route path="/About" element={<About />} />
                    <Route path="/Career" element={<Career />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/Media" element={<Media />} />
                    <Route path="/Leadership" element={<Leadership />} />
                    <Route path="/Cleints" element={<Cleints />} />
                    <Route path="/Whychooseus" element={<Whychooseus />} />
                    <Route path="/Ordertracking" element={<Ordertracking />} />
                    <Route path="/carousel" element={<Carousel/>} />
                    <Route path="/Vesselschedule" element={<Vesselschedule/>} />
                    <Route path="/Documents" element={<Documents/>} />
           
                    <Route path="/demo" element={<Demo/>} />
                    <Route path="/vessel" element={<Vessel1/>} />
                    {/* <Route path="/vessel-schedule" element={<Vessel_schedule/>} /> */}
                    
                  </Routes>
                  <Footer/>
          </Router>
              
            </>
       )
  }
}

export default App