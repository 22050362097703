import React, { Component } from 'react'
import {Link,NavLink} from "react-router-dom";
import Pdf from "../brochure.pdf"


export default function Navbar() {
   const [model, setmodel] = React.useState(false);
   const [t, st] = React.useState(false);
 
    return (
        <>
          {
                  model===true &&  <>
                  
            
    <div id="menu" className=" model mbmenu"  role="dialog" style={{visibility: t===true ? "hidden" : 'visible'}}  aria-hidden="true">
        <div className="modal-dialog modal-dialog-slideout" role="document">
        
            <div className="modal-content full">
                <div className="modal-header" onClick={()=>setmodel(false)}>
                    <i className="icon-close fas fa-arrow-right"></i>
                </div>
                <div className="menu2 modal-body">
                    <div className="row w-100">
                        <div className=" p-0  col-12  menu-li list-none">
                        <li className="nav-item"> <NavLink onClick={()=>st(true)} to="/Vesselschedule" style={{color: 'white'}} className=" p-2   btn-vessel btn primary-button " >Vessel Schedule</NavLink></li>
                    <li className="nav-item"> <NavLink onClick={()=>st(true)} to="/Ordertracking" style={{color: 'white'}} className=" p-2  mt-2 btn-vessel  btn primary-button "  >Order Tracking</NavLink></li>
                        <li className="nav-item">  <NavLink  onClick={()=>st(true)}  to='/' style={{fontWeight:"bold"}} className="nav-link1">HOME</NavLink> </li>
               <li className="nav-item">          
                        
      <label for="touch"><span className='span nav-link1'>COMPANY</span></label>               
      <input type="checkbox" id="touch"/> 
    
      <ul class="slide">
      <li className="nav-item"> <NavLink to='/About' onClick={()=>st(true)}  className="nav-link1"  >About Us</NavLink> </li>
                            <li className="nav-item"> <NavLink to='/Career' onClick={()=>st(true)} >Career</NavLink> </li>
                            <li className="nav-item "> <NavLink to='/Cleints' onClick={()=>st(true)} >Clientele</NavLink> </li>
                            <li className="nav-item"> <NavLink to='/Whychooseus'  onClick={()=>st(true)} >Why Choose Us </NavLink> </li>
                        
                            <li className="nav-item"> <NavLink to='/Leadership' onClick={()=>st(true)} >Leadership Team</NavLink> </li>
      </ul>
    </li>
                      
                 
    
                    <li className="nav-item"> <NavLink  to="/Services" onClick={()=>st(true)}  style={{fontWeight:"bold"}}   className="nav-link1" >SERVICES</NavLink> </li>
                    
                    
                    <li className="nav-item"> <NavLink to="/Media" onClick={()=>st(true)} style={{fontWeight:"bold"}}  className="nav-link1" >MEDIA</NavLink> </li>
                    <li className="nav-item"> <NavLink to="/Contact" onClick={()=>st(true)} style={{fontWeight:"bold"}}  className="nav-link1">CONTACT</NavLink> </li>
                    <li className="nav-item">          
                        
                        <label for="download"><span className='span nav-link1'>DOWNLOADS</span></label>               
                        <input type="checkbox" id="download"/> 
                      
                        <ul class="slide">
                        <li className="nav-item"> <a href={Pdf}  Target='_blank' > Get Brochure</a> </li>
                    <li className="nav-item"> <NavLink  to="/Documents" onClick={()=>st(true)} >Documents</NavLink> </li>
                        </ul>
                      </li>
                                    
                        </div>
                        {/* <div className="contacts p-0 col-12 text-center">
                      
                        </div> */}
                    </div>
                    
                </div>
              
            </div>
        </div>
    </div>
                  </>
}
    <header >
     
        <nav className="navbar navbar-expand top">
            <div className="container header">
           
                <ul className="navbar-nav">
                    <li className="nav-item"> <a className="nav-link pl-0">Open Hours: Mon - Sun - 6AM - 7:30PM</a> </li>
                </ul> 
    
                {/* <div className="ml-auto">
                
                </div>  */}
                <ul className="navbar-nav">
                    <li className="nav-item"> <a className="nav-link " href="tel:9879193900"> +91 98791 93900 </a> </li>
                    <li className="nav-item"> <a className="nav-link " href="mailto:oprs@pushpaklogistics.co.in">cs@pushpaklogistics.co.in</a> </li>
                </ul>
                <ul className="navbar-nav">
                    {/* <li className="nav-item"> <a href="http://52.66.213.23/api/pdf/vessel-schedule-new.php" target="_blank" style={{color: 'white'}} className=" p-2  mx-2 btn-vessel btn primary-button nav-link" >Vessel Schedule</a></li> */}
                    <li className="nav-item"> <Link to="/Vesselschedule" style={{color: 'white'}} className=" p-2  mx-2 btn-vessel btn primary-button nav-link" >Vessel Schedule</Link></li>
                    <li className="nav-item"> <Link to="/Ordertracking" style={{color: 'white'}} className=" p-2  mx-2 btn-vessel  btn primary-button nav-link"  >Order Tracking</Link></li>
               
                </ul>
                {/* <ul className="navbar-nav icons">
                    <li className="nav-item social"> <a className="nav-link" href="https://www.facebook.com/PushpakLogistics">&nbsp;</a> </li>
                    <li className="nav-item social"> <a className="nav-link" href="https://twitter.com/Pushpaklogist">&nbsp;</a> </li>
                    <li className="nav-item social"> <a className="nav-link pr-0" href="#">&nbsp;</a> </li>
                </ul> */}
            </div>
        </nav> 
     
        <nav className="navbar navbar-expand navbar-fixed sub">
            <div className="container header">
                <Link to="/" className="navbar-brand light" > <span className="brand"> <img src="assets/images/logo/pushpak.svg" className="logo-respo"  alt='logo'/>  
                   <img src="./assets/images/tag7.png" className="img-respo pl-2"  alt="tag" />
                    </span>
                </Link>
             
    
                <div className="ml-auto"></div>
               
                <ul className="navbar-nav items mx-auto">
                {/* <div className="d-xl-none  d-lg-block mb-2"> <Link to="/Vesselschedule" style={{color: 'white'}} className=" p-2 btn-vessel btn primary-button nav-link" >Vessel Schedule</Link>
            </div> 
      
                <div className="d-xl-none  d-lg-block "> <Link to="/Ordertracking" style={{color: 'white'}} className=" p-2 btn-vessel btn primary-button nav-link" >Order Tracking</Link>
            </div>  */}
         
                {/* <!-- <div className="d-sm-inline-flex"> <a className="vbtn p-2 smooth-anchor btn primary-button nav-link" href="./vessel.php">Vessel Schedule</a></div> -->
                    <!-- <div className="d-sm-inline-flex"> <a className="vbtn p-2 smooth-anchor btn primary-button nav-link" href="http://52.66.213.23/api/pdf/vessel-schedule-new.php">Vessel Schedule</a>
                    </div> --> */}
    
                    <li className="nav-item"> <Link to='/' className="nav-link">HOME</Link> </li>
                    <li className="nav-item dropdown"> <a className="nav-link">COMPANY </a>
                        <ul className="dropdown-menu">
                            <li className="nav-item"> <Link to='/About'  className="nav-link" >About Us</Link> </li>
                            <li className="nav-item"> <Link to='/Career' className="nav-link">Career</Link> </li>
                            <li className="nav-item "> <Link to='/Cleints' className="nav-link" >Clientele</Link> </li>
                            <li className="nav-item"> <Link to='/Whychooseus' className="nav-link" >Why Choose Us </Link> </li>
                            {/* <li className="nav-item"> <a className="nav-link" href="mission-vision.php">Mission &amp; Vision</a> </li> */}
                            <li className="nav-item"> <Link to='/Leadership' className="nav-link" >Leadership Team</Link> </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown"> <a className="nav-link" href="#">DOWNLOADS</a> 
                        <ul className="dropdown-menu">
                            <li className="nav-item"> <a href={Pdf}  Target='_blank' className="nav-link"> Get Brochure</a> </li>
                            <li className="nav-item"> <Link to="/Documents" className="nav-link" >Documents</Link> </li>
                        </ul>
                    </li>
    
                    <li className="nav-item"> <Link to="/Services#"   className="nav-link" >SERVICES</Link> </li>
                    
                    <li className="nav-item   d-xl-none  d-lg-block"> <Link to="Ordertracking" className="nav-link">ORDER TRACKING</Link> </li>
                    <li className="nav-item"> <Link to="/Media" className="nav-link" >MEDIA</Link> </li>
                    <li className="nav-item"> <Link to="/Contact" className="nav-link">CONTACT</Link> </li>
                </ul>
            
                <ul className="navbar-nav toggle">
                    <li className="nav-item ">
                        <a className="nav-link" onClick={()=>{st(false); setmodel(true); }}>
                            <i className="icon-menu m-0" style={{color: "black", fontWeight: "bold"}}></i>
                        </a>
                            
                    </li>
                </ul>
            </div>
        </nav>
       
          
    </header>
    
    
    
        </>
        )
}

  