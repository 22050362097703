import React, { Component } from 'react'
import "./Document.css"
import Undertaking from "../Undertaking Letter.docx"
import vgm from "../VGM DECLARATION FORMAT.DOCX"
export class Document extends Component {
  render() {
    return (
     <>
     <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color text-uppercase">Document</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="services" className="section-2 odd offers services-bg">
        <div className="container">
        <div className="card shadow card-doc">
                    <div className="card-header h2 doc-header text-uppercase text-center">
                        Documents
                    </div>
                    <div className="card-body doc-body">
    {/* <h5 className="card-title">Special title treatment</h5>
    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            <div className='row'>
                           
                            <div className='col-lg-6 doc-item '>
                            <div className="text-center title-doc ">Undertaking</div>
                            <a className="doc-link justify-content-center d-flex" href={Undertaking}>Click To Downloads</a>
                            </div>
                            <div className='col-lg-6 doc-item '>
                            <div className="text-center title-doc ">VGM</div>
                            <a className="doc-link justify-content-center d-flex" href={vgm}>Click To Downloads</a>
                            </div>
                            
                            </div>
                    </div>
        </div>
        </div>
    </section>
     </>
    )
  }
}

export default Document