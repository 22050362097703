
import "./Contact.css"
import React, { useState } from "react";
import Axios from 'axios'

export default function Contact() {
 const ip ="http://localhost:3001"
        const [status, setStatus] = useState("");
        const [resp, setresp] = useState("");
        
        const handleSubmit = async (e) => {
          e.preventDefault();
          setStatus("Sending...");
          console.log(e.target.elements.name.value);
          const { name, email, message,phone } = e.target.elements;
          console.log(name, email, message)
          if(name.value==null || name.value==''){
             setStatus("Name field required");
          }
          else if(email.value=='' || email.value==null){
             setStatus("Email field required");
              
          }
          else if(phone.value=='' || phone.value==null){
              setStatus("phone field required");
              
            }
            else if(phone.value.length!=10){
                setStatus("Phone Number has 10 digits");
                
            }
            else if(message.value=='' || message.value==null){
               setStatus("Message field required");
                
            }
            else if(message.value.length<=10){
               setStatus("Message must be at least 10 characters");
                
            }
          else{
              
              let details = {
                name: name.value,
                email: email.value,
                message: message.value,
                phone: phone.value,
              };
            
              Axios.post(ip+`/api/contact`, details).then((response) => {
                // console.log("success", response.data);
                setresp(response.data)
                console.log(response.data)
                console.log(resp.mail)
                
             if (resp.mail===1){
                //  console.log("success")
                 setStatus('sucessfully send');
                 var message=  setTimeout(() => {
                        setStatus('');
                      }, 6000);
                        
                      clearTimeout(message);
             }
             if(resp.mail===0){
                 setStatus('Something went wrong please try again');
                 var message=  setTimeout(() => {
                    setStatus('');
                  }, 6000);
                    
                  clearTimeout(message);
                    
                //   clearTimeout(message);
             }
              }).catch((error) => {
                console.log(error);
              });
              
          }
          
        //   let response = await fetch("http://localhost:5000/contact", {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json;charset=utf-8",
        //     },
        //     body: JSON.stringify(details),
        //   });
        //   setStatus("Submit");
        //   let result = await response.json();
        //   alert(result.status);
        };
    
  
    return (
        <>
         <section id="slider" className="hero p-0 odd featured">
              <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
                  <div className="swiper-wrapper">
  
                     
                      <div className="swiper-slide slide-center">
  
                       
                          <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img"  alt="Banner" />
  
                          <div className="slide-content row text-center">
                              <div className="col-12 mx-auto inner">
  
                                
                                  {/* <!-- <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                      <ol className="breadcrumb">
                                          <li className="breadcrumb-item"><a href="index.html" style="color: black">Home</a></li>
                                          <li className="breadcrumb-item active" aria-current="page">Career</li>
                                      </ol>
                                  </nav> --> */}
                                  <h1 className="mb-0 title effect-static-text banner-color" >CONTACT</h1>
                              </div>
                          </div>
                      </div>
  
                  </div>
              </div>
          </section>
         
      <section id="about" className="section-1 odd highlights image-right bg-white">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-md-4 pr-md-5 align-self-top text">
                      <div  className="row intro">
                          <div className="col-12 p-0">
                              <span className="pre-title m-0">Talk to an expert</span>
                              <h2 className='contact-main-title' >Our<br/>Business<br/>Units</h2>
  
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-4  pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4 >Jamnagar Office :- </h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:9879193900" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              +91 98791 93900
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto:oprs@pushpaklogistics.co.in" className="nav-link contact-details">
                                              <i className="fas fa-envelope mr-2"></i>
                                              oprs@pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link  text-justify" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              1st Floor, Standard house, Opp. Celebration Hotel, Indira Marg, Jamnagar
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
  
                  <div className="col-12 col-md-4 mx-auto  pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4>Registered Office :- </h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:9887217357" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              +91 9099411711
                                          </a>
  
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto:ksg@pushpaklogistics.co.in" className="nav-link contact-details" >
                                              <i className="fas fa-envelope mr-2"></i>
                                              cs@pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link text-justify" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              102/E ,Shubh Shanti Complex, E Wing, M G Road, Dahanukar Wadi, Nr Mahatre Plaza, Kandivali (W), Mumbai - 400067
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
              <div className="row">
                  <div className="col-12 col-lg-4 offset-lg-4   pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4 >Cochin Office :- </h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:4844038070" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              4844038070
                                          </a>
                                          <a href="tel:4844038071" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              4844038071
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto: cochin@pushpaklogistics.co.in" className="nav-link contact-details" >
                                              <i className="fas fa-envelope mr-2"></i>
                                              cochin@pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link  text-justify" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              Pushpak Logistics Solutions LLP
                                              14/14763,
                                              2nd floor Ashoka complex
                                              Srampickal Road
                                              Chullikal – 682005.
  
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-4  pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4 >Tuticorin Office :- </h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:04612332131" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              0461 - 2332131
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto:tuti@pushpaklogistics.co.in" className="nav-link contact-details" >
                                              <i className="fas fa-envelope mr-2"></i>
                                              tuti@pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link contact-details" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              41-C, Pereira Street,
                                              Tuticorin - 628001
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                 
                  <div className="col-12 col-lg-4 offset-lg-4   pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4 >Kishangarh Office :- </h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:9887217357" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              +91 9887217357
                                          </a>
                                          <a href="tel:8742012604" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              +91 8742012604
                                          </a>
                                          <a href="tel:01463299436" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              01463 - 299436
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto:ksg@pushpaklogistics.co.in" className="nav-link contact-details" >
                                              <i className="fas fa-envelope mr-2"></i>
                                              ksg@pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link text-justify" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              office no C24 Third floor near Harmara chauraha Ganpati square marble ind area Jaipur by pass road Madanganj - Kishangarh 305801
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-4  pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4 >Gandhidham Office:-</h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:9979887806" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              +91 99798 87806
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto:mundra@pushpaklogistics.co.in" className="nav-link contact-details" >
                                              <i className="fas fa-envelope mr-2"></i>
                                              mundra@pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link  text-justify" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              208, Silver Arc, Plot No. 57, Sector 8, Nr. Maharashtra Mandal, Tagore Road, Sector 8, Gandhidham, Gujarat 370201
  
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
               
                  <div className="col-12 col-lg-4 offset-lg-4  pr-md-5 align-self-top text">
                      <div className="row items">
                          <div  className="col-12 p-0 pr-md-4 item">
                              <div className="contacts">
                                  <h4 >Kolkata Office:-</h4>
                                  <ul className="navbar-nav">
                                      <li className="nav-item">
                                          <a href="tel:9979887806" className="nav-link contact-details" >
                                              <i className="fas fa-phone-alt mr-2"></i>
                                              +91 90627 46366
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="mailto:mundra@pushpaklogistics.co.in" className="nav-link contact-details" >
                                              <i className="fas fa-envelope mr-2"></i>
                                              @pushpaklogistics.co.in
                                          </a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#" className="nav-link  text-justify" >
                                              <i className="fas fa-map-marker-alt mr-2"></i>
                                              PUSHPAK LOGISTICS SOLUTIONS LLP,
                                              35 GANESH Chandra AVENUE
                                              2 nd floor,
                                              Kolkata 700013
                                          </a>
                                      </li>
  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  
              </div>
          </div>
      </section>
      <section id="slider" className="hero p-0 odd bg-light pt-3 mb-2">
          <div className="container">
  
              <div className="swiper-wrapper">
  
               
                  <div className="swiper-slide slide-center">
                      <div className="slide-content row">
                          <div className="col-12 mx-auto inner">
  
                          
                              <h1 className="title effect-static-text p" style={{color: "#21333e"}}>Get in Touch</h1>
  
                              <form id="contactform" method="post"   onSubmit={handleSubmit}>
                                  <div className="row form-group-margin">
                                      <div className="col-12 col-md-4 col-lg-4 m-0 p-2 input-group">
                                          <input type="text" id="name" name="name" className="form-control field-name" placeholder="Name"/>
                                      </div>
                                      <div className="col-12 col-md-4 col-lg-4 m-0 p-2 input-group">
                                          <input type="email" id="email" name="email" className="form-control field-email" placeholder="Email"/>
                                      </div>
                                      <div className="col-12 col-md-4 col-lg-4 m-0 p-2 input-group">
                                          <input type="text" id="phone" name="phone" className="form-control field-phone" placeholder="Phone"/>
                                      </div>
  
                                      <div className="col-12 m-0 p-2 input-group">
                                          <textarea name="message" id="message" className="form-control field-message" placeholder="Message"></textarea>
                                      </div>
                                      <div className="col-12 col-12 m-0 pl-md-2">
                                          <span>{status}</span>
                                      </div>
                                      <div className="col-12 input-group m-0 p-2 ">
                                          <button className="btn primary-button btn1" type="submit" name="action">SEND</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
        </>
      )
}

// export class Contact extends Component {
//   render() {
   
//   }
// }

// export default Contact