import React, { Component } from 'react'
import "./Whychooseus.css"
export class Whychooseus extends Component {
  render() {
    return (
      <>
       <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color">Why Choose Us</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    
    <section  className="section-1  bg-light ">
        <div className="container">
            <div className="row justify-content-center items">
                    <div  className="col-12 col-md-6 col-lg-6 item whyitems">
                        <div className="card card4">
                            <i className="icon mx-3 fa fa-map-signs"></i>
                            <h4 className="pl-3">Complete Coverage of West Coast to/fro East Coast</h4>
                            <ul  className="list-about">

                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>Offices in all ports in Gujarat </p>
                                </li>
                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>HO in Jamnagar. Branches in Bengaluru, Cochin, Gandhidham, Hazira, Kishangarh, Kolkata, Mumbai, Mundra, Mangalore, Mysore, Pipavav and Tuticorin</p>
                                </li>
                              <li>&nbsp;</li>
                             
                            </ul>
                          
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                        </div>
                    </div>
                    <div  className="col-12 col-md-6 col-lg-6 item whyitems">
                        <div className="card bg-card2">
                            <i className="icon mx-3 fas fa-ship"></i>
                            <h4 className="pl-3 text-white">Flexible Delivery & Transport Mode</h4>
                            <ul  className="list-about">

                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p> Door-2-Door/Door-2-Port/Port-2-Port/Port-2-Door & other modes of tailor-made packages to suit various need of our customers </p>
                                </li>
                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>HO in Jamnagar. Branches in Bengaluru, Cochin, Gandhidham, Hazira, Kishangarh, Kolkata, Mumbai, Mundra, Mangalore, Mysore, Pipavav and Tuticorin</p>
                                </li>
                              
                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>Road, Rail, Ocean</p>
                                </li>
                              
                            </ul>
                            
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                        </div>
                    </div>
                    <div  className="col-12 col-md-6 col-lg-6 item whyitems">
                        <div className="card bg-card2">
                            <i className="icon mx-3 fa fa-shipping-fast"></i>
                            <h4 className="pl-3 text-white">Competitive Freight, Faster Delivery</h4>
                            <ul  className="list-about">

                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p> A good tie up with all major Indian coastal lines to provide better service without any delay </p>
                                </li>
                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>Schedule services with fixed day departure and arrival (eta/etd)</p>
                                </li>
                                                                           
                            </ul>
                            {/* <p>&nbsp;</p> */}
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                        </div>
                    </div>
                    <div  className="col-12 col-md- col-lg-6 item whyitems">
                        <div className="card card4">
                            <i className="icon mx-3 fa fa-project-diagram"></i>
                            <h4 className="pl-3">Pan-India Road Connectivity</h4>
                            <ul  className="list-about">

                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>Own 105+ trucks/multi axle trailers & 250+ attached fleets network all over India to provide a good service without any delay</p>
                                </li>
                              
                                                                           
                            </ul>
                            <p>&nbsp;</p>
                           
                            {/* <p>&nbsp;</p> */}
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                        </div>
                    </div>
                    <div  className="col-12 col-md-6 col-lg-6 item whyitems">
                        <div className="card card4">
                            <i className="icon mx-3 fa  fa-user-shield"></i>
                            <h4 className="pl-3">Your cargo in Safe hands</h4>
                            <ul  className="list-about">

                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p>Reliable service, frequent vessel schedules, hassle free shipping</p>
                                </li>
                              

                                <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                    <p> Your most reliable service provider with best-in-industry cargo safety protocol</p>
                                </li>
                              
                                                                           
                            </ul>
                            <p>&nbsp;</p>
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                        </div>
                    </div>
            </div>
        </div>
    </section>
      </>
    )
  }
}

export default Whychooseus